<template>
  <div class="app">
    <el-main>
      <el-card class="card">
        <h3>欢迎来到系统首页</h3>
        <p>这是一个简洁的系统管理面板首页。</p>
      </el-card>
      <el-cascader
        class="select"
        v-model="value"
        :options="options"
        :props="{ expandTrigger: 'hover' }"
        @change="handleChange"
      ></el-cascader>
    </el-main>

    <router-view></router-view>
  </div>
</template>

<script>
import redirect from "@/utils/Redirect";
export default {
  name: "app",
  data() {
    return {
      value: ["2-1"],
      options: [
        {
          value: "2-1",
          label: "搜索查找",
        },
        {
          value: "2-2",
          label: "职工信息",
        },
        {
          value: "4",
          label: "关于",
        },
      ],
    };
  },
  methods: {
    handleChange(value) {
      switch (value[value.length - 1]) {
        case "2-1":
          redirect(this, "/index/search");
          break;
        case "2-2":
          redirect(this, "/index/list");
          break;
        case "4":
          redirect(this, "/index/about");
          break;
      }
    },
  },
};
</script>

<style scoped>
.card {
  background-color: #409eff;
  color: #fff;
}
.select {
  margin-top: 10px;
}
</style>