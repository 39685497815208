<template>
  <div class="app">
    <div class="search">
      <el-row class="box">
        <el-card class="card" shadow="always">
          <el-input
            class="inp"
            v-model="input"
            placeholder="请输入内容"
            @keydown.native="enter"
          ></el-input>
          <el-button type="primary" @click="search">查找</el-button>
        </el-card>
      </el-row>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import axios from "axios";
import redirect from "@/utils/Redirect";
export default {
  name: "app",
  data() {
    return {
      input: "",
      targetIds: [],
    };
  },
  methods: {
    enter(e) {
      if (e.keyCode == 13) this.search();
    },
    async search() {
      this.targetIds = [];
      if (
        this.input.trim().length === 0 ||
        this.input === null ||
        typeof this.input === "undefined"
      ) {
        this.searchNon(this.input);
        return;
      }

      let words = this.input.split(" ");
      words = words.filter((item) => item.trim().length !== 0);

      this.targetIds = await axios
        .post(`${process.env.VUE_APP_BASE_URL}/teachers/fields`, words)
        .then((res) => {
          return res.data.data;
        })
        .catch((err) => {
          this.$message.error("请求失败：" + err.stack);
          console.log("Error v");
          console.log(err);
        });

      if (this.targetIds.length == 0) {
        this.searchNon(this.input);
        return;
      }
      if (this.targetIds.length == 1) {
        let input = this.input.replace(/\s+/g, "+");

        if (this.$route.path.indexOf("index") != -1)
          redirect(
            this,
            `/index/search/detail/keys=${input}/${this.targetIds[0]}`
          );
        else
          redirect(this, `/search/detail/keys=${input}/${this.targetIds[0]}`);
        return;
      }
      if (this.targetIds.length > 1) {
        let input = this.input.replace(/\s+/g, "+");

        if (this.$route.path.indexOf("index") != -1)
          redirect(
            this,
            `/index/search/search_list/keys=${input}/ids=${this.targetIds.join(
              "+"
            )}`
          );
        else
          redirect(
            this,
            `/search/search_list/keys=${input}/ids=${this.targetIds.join("+")}`
          );
        return;
      }
    },
    searchNon(word) {
      this.$message({
        message: `"${word}" 找不到对应结果 >_< 试试别的关键词吧。`,
        type: "warning",
      });
    },
  },
};
</script>

<style scoped>
.search {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.box {
  display: inline-block;
  height: 100%;
}
.card {
  background-color: #d9ecff;
}
.inp {
  width: calc(100vw - 200px);
  margin-right: 20px;
}
</style>